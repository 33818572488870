<template>
  <div>
      <v-btn
        absolute
        top
        right
        @click="togglePanel"
        x-small
        :color="'success'"
        class="btn-panel"
      >
        <v-icon>{{ showPanel ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left' }}</v-icon>
      </v-btn>

    <v-row>
      <v-col :lg="showPanel ? 8 : 12">
        <v-dialog
          v-if="showQuoteEdit"
          v-model="showQuoteEdit"
          width="70%"
          persistent
          transition="dialog-bottom-transition"
        >
          <QuoteEdit :items="itemsSAP" @close="showQuoteEdit = false"></QuoteEdit>
        </v-dialog>
        <div v-if="dialogLoad">
          <v-dialog
            v-model="dialogLoad"
            persistent
            transition="dialog-bottom-transition"
          >
            <order-purchase-edit
              @onClose="onClose()"
              @onCloseClear="
                onClose();
                itemsSAP = [];
              "
              @clearItems="itemsSAP = []"
              @clearItemsCard="clearItemsCard"
              :typeOrder="3"
              :order="order"
            ></order-purchase-edit>
          </v-dialog>
        </div>

        <s-toolbar
          add
          remove
          excel
          @excel="excel($event)"
          @add="showDialog()"
          @removed="deleted(itemsSAP)"
          search-input
          label="Requerimientos de Servicio "
        >
          <template v-slot:options>
            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  text
                  v-on="on"
                  elevation="0"
                  @click="showQuoteEdit = true"
                >
                  <v-icon style="font-size: 16px">fas fa-book-reader</v-icon>
                </v-btn>
              </template>
              <span>Generar Solicitud de Cotización</span>
            </v-tooltip>
          </template>
        </s-toolbar>
        <v-card elevation="0">
          <v-container>
            <v-col style="" class="pt-0">
              <v-row justify="center">
                <v-col lg="4">
                  <s-select-area label="Filtro por Área" v-model="filter.AreID">
                  </s-select-area>
                </v-col>

                <v-col lg="4">
                  <s-date
                    label="Fecha Inicio"
                    v-model="filter.BeginDate"
                    clearable
                  ></s-date>
                </v-col>
                <v-col lg="4">
                  <s-date
                    label="Fecha Fin"
                    v-model="filter.EndDate"
                    clearable
                  ></s-date>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>

        <v-data-table
          fixed-header
          :items="itemsDetail"
          v-model="itemsSAP"
          height="420"
          dense
          show-select
          item-key="DerID"
          :loading="loading"
          :headers="config.headers"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn x-small elevation="0" @click="showRowData(item)">
              <v-icon color="success" x-small>
                    fas fa-check
                  </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.DerStatus="{ item }">
            <v-chip
              x-small
              :color="
                item.DerStatus == 7 ? 'error' : item.DerStatus == 6 ? 'primary' : ''
              "
            >
              {{
                item.DerStatus == 7
                  ? "Artículo de Baja"
                  : item.DerStatus == 6
                  ? "Cotizado"
                  : "Autorizado"
              }}
            </v-chip>
          </template>
          <template v-slot:item.ReqAuthorizationDate="{ item }">
            {{ $moment(item.ReqAuthorizationDate).format("L") }}
          </template>
        </v-data-table>

        <!-- <v-row>
          <v-col
            ><s-crud
              @rowSelected="rowSelected($event)"
              :single-row="false"
              deleteNoFunction
              @delete="deleted($event)"
              :filter="filterRequirement"
              title="Atención de Requerimientos de Servicios"
              remove
              excel
              ref="crudAttention"
              pdf
              :config="config"
              height="410"
              add
              add-no-function
              @add="showDialog()"
            >
              <template v-slot:options>
                <v-tooltip bottom="">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      text
                      v-on="on"
                      elevation="0"
                      @click="showQuoteEdit = true"
                    >
                      <v-icon style="font-size: 16px">fas fa-book-reader</v-icon>
                    </v-btn>
                  </template>
                  <span>Generar Solicitud de Cotización</span>
                </v-tooltip>
              </template>
              <template v-slot:filter>
                <v-col style="">
                  <v-row justify="center">
                    <v-col lg="3">
                      <s-select-area v-model="filterRequirement.AreID" label="Área">
                      </s-select-area
                    ></v-col>

                    <v-col lg="2">
                      <s-date
                        v-model="filterRequirement.BeginDate"
                        clearable
                        nullable
                        label="Fecha Inicio"
                      ></s-date
                    ></v-col>
                    <v-col lg="2">
                      <s-date
                        clearable
                        v-model="filterRequirement.EndDate"
                        nullable
                        label="Fecha Fin"
                      ></s-date
                    ></v-col>
                  </v-row>
                </v-col> </template></s-crud></v-col
        ></v-row> -->
      </v-col>

      <v-slide-x-transition>
        <v-col class="transition-column-right" :class="{ 'hidden-panel': !showPanel }">
          <v-card class="mx-auto" max-width="500" border flat>
            <v-toolbar class="s-card-title" dense rounded elevation="0">
              <v-toolbar-title class="">Requerimientos 
                <v-chip
                  v-if="itemsCard.length"
                  class="ml-2"
                  small
                  color="blue"
                  text-color="white"
                >
                  {{ itemsCard.length }}
                </v-chip>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text x-small @click="showDialogCard()">
                <v-icon x-small>far fa-eye </v-icon>
              </v-btn>
            </v-toolbar>
            <v-col>

              <v-list dense>
                <template v-if="itemsCard.length">
                  <v-list-item
                    v-for="(item, index) in itemsCard"
                    :key="index"
                    class="d-flex justify-space-between align-center"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>{{ item.ReqDocumentNumber }}</strong> - {{ item.DerDescription }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Solicitante: {{ item.UsrName }} | Línea: {{ item.DerNumLine }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon small @click="removeItem(index)">
                        <v-icon color="error">mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <template v-else>
                  <v-list-item>
                    <v-list-item-content class="text-center">
                      <v-list-item-title>No hay requerimientos añadidos</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>

            </v-col>
          </v-card>
        </v-col>
      </v-slide-x-transition>
    </v-row>
  </div>
</template>
<!-- </s-crud></v-col> </v-row>
 </div>
</template> -->
<script>
import SContactSupplier from "../../../components/Utils/Logistics/SContactSupplier.vue";
import SSupplier from "../../../components/Utils/Logistics/SSupplier.vue";
import _sRequirementDetail from "../../../services/Logistics/RequirementDetailService";
import _sRequirement from "../../../services/Logistics/RequirementService";
import OrderPurchaseEdit from "../OrderPurchase/OrderPurchaseEdit.vue";
import QuoteEdit from "@/views/Logistics/Quote/QuoteEdit.vue";
export default {
  name: "RequirementService",
  components: { SSupplier, SContactSupplier, OrderPurchaseEdit, QuoteEdit },
  props: {},

  data: () => ({
    loading: false,
    TaxCode: null,
    selectedSAP: [],
    order: { TypeOrder: 3, detail: [] },
    showQuoteEdit: false,
    showPanel: false,
    itemsConcept: [],
    itemsAccount: [],
    itemsCard: [],
    AcoID: null,
    ConID: null,
    ConName: null,
    SupID: "",
    Supplier: null,
    itemsSAP: [],
    dialogLoad: false,
    itemsDetail: [],
    filterRequirement: { BeginDate: null, EndDate: null },
    filter: {
      length: -1,
      AreID: 0,
      BeginDate: null,
      EndDate: null,
      TypeRequirement: 3,
      ItmsGrpCod: 0,
      Everybody: 1,
      searchText: "",
    },
    config: {
      model: {
        DerID: "ID",
        ReqAuthorizationDate: "datetime",
        ReqDate: "datetime",
      },
      service: _sRequirementDetail,
      headers: [
        {
          text: "Acción",
          value: "action",
          sortable: false,
          width: 100,
        },
        { text: "N° Requerimiento", value: "ReqDocumentNumber", width: 150 },
        { text: "Solicitante", value: "UsrName", width:100 },
        { text: "Linea", value: "DerNumLine", sortable: false },
        //  { text: "Solicitud", value: "ReqDate", sortable: false,width:125 },
        { text: "Autorización", value: "ReqAuthorizationDate", width: 125 },
        { text: "Descripción", value: "DerDescription", width: 600 },

        { text: "Centro de Costo", value: "CceName", width: 250 },
        { text: "Linea Negocio", value: "BslName", width: 250 },
        { text: "Tipo Costo", value: "TypeCostName", width: 100 },
        { text: "Proyecto", value: "PrjName", width: 250 },
        {
          text: "Comentario",
          value: "DerObservation",
          sortable: false,
          width: 300,
        },
      ],
    },
  }),
  methods: {
    refresh() {
      this.loading = true;
      _sRequirementDetail
        .pagination(
          { filter: this.filter, searchText: "" },
          this.$fun.getUserID()
        )
        .then((r) => {
          // console.log(r.data.data);
          this.loading = false;
          this.itemsDetail = r.data.data;
        });
    },
    change() {
      this.value = this.$fun.getDate();
      if (this.filter.Everybody == 1) {
        this.value = null;
        this.filter.BeginDate = this.value;
        this.filter.EndDate = this.value;
      } else {
        this.filter.BeginDate = this.value;
        this.filter.EndDate = this.value;
      }
      this.clearDate = this.filter.Everybody == 1 ? true : false;
      this.$emit("input", this.value);
    },
    excel() {
      this.config.service
        .excel({ filter: this.filter }, this.$fun.getUserID(), this.row)
        .then((r) => {
          this.$fun.downloadFile(
            r.data,
            this.$const.TypeFile.EXCEL,
            "Requerimiento Servicios"
          );
        });
    },

    deleted(items) {
      items.map((item) => {
        item.DerStatus = 9;
        item.UsrUpdateID = this.$fun.getUserID()
        return item;
      });
      this.$fun.alert("Seguro de Anular?", "question").then((r) => {
        if (r.value)
          _sRequirementDetail.save(items, this.$fun.getUserID()).then(() => {
            this.$fun.alert("Anulado Correctamente", "success");
            this.refresh();
          });
      });
    },
    onClose() {
      this.dialogLoad = false;
      this.refresh();
    },
    onSave() {},
    showDialog() {
      if (this.itemsSAP.length > 0) {

        this.order.detail = this.itemsSAP;
        for (let i = 0; i < this.order.detail.length; i++) {
          this.order.detail[i].OddDescription =
            this.order.detail[i].DerDescription;
          this.order.detail[i].OddObservation =
            this.order.detail[i].DerObservation;
          this.order.detail[i].OddQuantity = 1;
          this.order.detail[i].Line = i + 1;
          this.order.detail[i].OddAccount = this.order.detail[i].ReqAccount;
        }

        this.dialogLoad = true;
      } else this.$fun.alert("No ha seleccionado ningún registro", "warning");
    },
    showDialogCard() {
      if (this.itemsCard.length > 0) {

        this.order.detail = this.itemsCard;
        for (let i = 0; i < this.order.detail.length; i++) {
          this.order.detail[i].OddDescription =
            this.order.detail[i].DerDescription;
          this.order.detail[i].OddObservation =
            this.order.detail[i].DerObservation;
          this.order.detail[i].OddQuantity = 1;
          this.order.detail[i].Line = i + 1;
          this.order.detail[i].OddAccount = this.order.detail[i].ReqAccount;
        }

        this.dialogLoad = true;
      } else this.$fun.alert("No ha seleccionado ningún registro", "warning");
    },
    rowClick: function (item, row) {
      this.selectedSAP = [item];
    },
    saveAccount(item) {
      if (this.AcoID != null) {
        let edit = this.itemsSAP.find((x) => x.Line == item.Line);
        let indexEdit = this.itemsSAP.indexOf(item);
        edit.AcoName = this.AcoID.CsaDescription;

        this.itemsSAP.splice(indexEdit, 1, edit);
      }
    },
    showRowData(row) {
      if (this.itemsCard.find((x) => x == row) == null) this.itemsCard.push(row);
      this.updateLocalStorage();
    },
    removeItem(index) {
      this.itemsCard.splice(index, 1);
      this.updateLocalStorage();
    },
    updateLocalStorage() {
      // Guardar en localStorage
      localStorage.setItem("serviceRequirement", JSON.stringify(this.itemsCard || []));
    },
    clearItemsCard() {
        this.itemsCard = []; // Limpiar itemsCard
        this.updateLocalStorage(); // Actualizar almacenamiento local
    },
    togglePanel() {
      if (this.showPanel) {
        const sidePanel = document.querySelector('.transition-column-right');
        if (sidePanel) {
          sidePanel.classList.add('hidden-panel');
        }

        setTimeout(() => {
          this.showPanel = false;
        }, 500);
      } else {

        this.showPanel = true;

        this.$nextTick(() => {
          const sidePanel = document.querySelector('.transition-column-right');
          if (sidePanel) {
            sidePanel.classList.remove('hidden-panel');
          }
        });
      }
    }
  },
  created() {
    const storedItems = localStorage.getItem("serviceRequirement");
    if (storedItems) {
      try {
        this.itemsCard = JSON.parse(storedItems);
      } catch (error) {
        console.error("Error al parsear los datos de localStorage:", error);
        this.itemsCard = [];
      }
    }

    this.filterRequirement.BeginDate = null;
    this.filterRequirement.EndDate = null;
    this.filterRequirement.TypeRequirement = 3;

    _sRequirementDetail
      .pagination(
        { filter: this.filter, searchText: "" },
        this.$fun.getUserID()
      )
      .then((r) => {
        this.itemsDetail = r.data.data;
      });
  },

  watch: {
    "filter.BeginDate"() {
      this.refresh();
    },
    "filter.EndDate"() {
      this.refresh();
    },
    "filter.Everybody"() {
      this.refresh();
    },
    "filter.ItmsGrpCod"() {
      this.refresh();
    },
    "filter.AreID"() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style>
.transition-column-right {
  transition: all 0.5s ease;
  opacity: 1;
  transform: scale(1);
}

.hidden-panel {
  opacity: 0;
  transform: scale(0.5);
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
}

.btn-panel {
  margin-right: -16px;
  margin-top: -3px;
  z-index: 100;
}
</style>